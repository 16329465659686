import React from 'react';
import styles from './About.module.scss';
import {useLocation} from "react-router-dom";
import multfilmStyles from "../Multfilm/Multfilm.module.scss";

const About = () => {
    const location = useLocation();

    return (
        <div style={{minHeight: '100vh'}}>
            <div className={styles.aboutBlock}>
                <div className={styles.aboutBlock_content}>
                    <h1>{location.pathname === '/about' ? 'Проект турында' : 'Проектның методикасы'}</h1>
                    <div className={styles.text}>
                        {
                            location.pathname === '/about' ? (
                                <>
                                    <p>
                                        Проект Казан мэриясе ярдәме белән «Татармультфильм» студиясе тарафыннан гамәлгә ашырылды.  Проект балалар өчен белем бирү, татар телен популярлаштыру юнәлешендәге мәдәни контентны  үстерүгә йөз тота.
                                    </p>
                                    <p>
                                        Әминә» проекты – нәни татар балаларында иң якын әйләнә-тирәне танып белү аша туган телләренә карата кызыксыну уяту, аны өйрәнү, башлангыч дәрәҗәдә аңлау өчен методик яктан эшләнгән кызыклы видеографик анимацион сериал.
                                    </p>
                                    <p>
                                        «Әминә» проекты Татарстан Республикасы Мәгариф һәм фән министрлыгы тарафыннан расланган программадагы лексик минимумга нигезләнә. Проект 32 җырлы анимацион фильмнан тора. Аларның һәрберсе билгеле бер темага багышланган һәм балаларга лексик минимумга кергән сүзләрне матур җыр сүзләре, балаларга якын, аңлаешлы иллюстрацияләр, мавыктыргыч анимация ярдәмендә истә калдырырга ярдәм итүне күздә тота. Практик әһәмияте, заманча методикаларга, сыналган алымнарга нигезләнеп эшләнүе әлеге проектның мәктәпкәчә белем бирү учреждениеләре һәм башлангыч мәктәп педагогларына да, ата-аналарга да кирәклеген раслый, аңа ихтыяҗ зур булуын күрсәтә.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        Проект мәктәпкәчә белем бирүнең методик һәм практик белгечләре белән килештереп тормышка ашырыла. Проект балалар бакчаларындагы сабыйларның татар телен башлангыч дәрәҗәдә белүенә һәм аңлавына ирешү максатын куеп эшләнә. Мәктәпкәчә белем бирү учреждениеләрендә һәр атна (32 атналык уку елы дәвамында) сериалны балалар белән карау һәм өйрәнү нәтиҗәле. Проект  татар телле төркемнәрдә дә, белем-тәрбия бирү рус телендә алып барыла торган төркемнәрдә дә, шулай ук башлангыч мәктәпләрдә дә кулланырга була.
                                    </p>
                                    <p>
                                        Анимацион сериалның аерым серияләрен балалар бакчаларында сөйләмне үстерү, танып белү шөгыльләре вакытында карау уңышлы.  “Әминә” җырын физкультминутлар өчен да куллану бик җайлы. Ел фасыллары белән бәйле бәйрәмнәр һәм башка чаралар вакытында (“Көзге уңыш” бәйрәме, “Гаилә” бәйрәме, “Кышны озату” бәйрәме һ.б.)  вакытында “Әминә”нең җырларын күмәкләп җырларга мөмкин. Алардан кыска инсценировкалар, уеннар ясарга да була. Бу исә балаларның сүзләрне тагын да яхшырак истә калдыруын тәэмин итәчәк.
                                    </p>
                                    <p>
                                        Проект мәктәпкәчә белем бирү учреждениеләре тәрбиячеләре һәм методистларының татар телен өйрәтү, аны уку-укытуга бәйле эшчәнлеген җиңеләйтәчәк.
                                    </p>
                                </>
                            )
                        }
                    </div>
                </div>
                <img className={styles.aminaPic} src="/img/aminaAboutPic.png" alt=""/>
            </div>
            <div className={`${multfilmStyles.footer} ${styles.footer_about}`}>
                <p>
                    Проект Казан мэриясе ярдәме белән «Татармультфильм» студиясе тарафыннан гамәлгә ашырылды.  Проект балалар өчен белем бирү, татар телен популярлаштыру юнәлешендәге мәдәни контентны  үстерүгә йөз тота.
                </p>
                <p>
                    © 2024 «Әминә». Барлык хокуклар якланган. Сайт материалларын язма рөхсәт белән генә файдаланырга ярый.
                </p>
            </div>
        </div>
    );
};

export default About;