// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_aboutBlock__apbXL {
  padding: 36px;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 748px;
  margin: 48px auto;
  display: flex;
  flex-direction: column;
  row-gap: 36px;
}
.About_aboutBlock__apbXL .About_aminaPic__dlrTY {
  width: 142px;
  height: 120px;
  margin: 0 auto;
}
.About_aboutBlock_content__106Ur {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.About_aboutBlock_content__106Ur h1 {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0%;
  text-transform: uppercase;
  color: #4C4C4C;
}
.About_aboutBlock_content__106Ur .About_text__HkgV7 {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.About_aboutBlock_content__106Ur .About_text__HkgV7 p {
  font-family: Ubuntu;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0%;
  color: #4C4C4C;
}

.About_footer_about__T-GEp {
  border-top: 4px solid #98A6FF;
  background-color: #8A9AFF;
}

@media screen and (max-width: 1024px) {
  .About_aboutBlock__apbXL {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .About_aboutBlock__apbXL {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/AboutPage/About.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEE;EAEE,aAAA;EACA,sBAAA;EACA,aAAA;AADJ;AAGI;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAFN;AAIM;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AAFR;;AAQA;EACE,6BAAA;EACA,yBAAA;AALF;;AAQA;EACE;IACE,UAAA;EALF;AACF;AAQA;EACE;IACE,UAAA;EANF;AACF","sourcesContent":[".aboutBlock {\n  padding: 36px;\n  background-color: #FFFFFF;\n  border-radius: 24px;\n  width: 748px;\n  margin: 48px auto;\n  display: flex;\n  flex-direction: column;\n  row-gap: 36px;\n\n  .aminaPic {\n    width: 142px;\n    height: 120px;\n    margin: 0 auto;\n  }\n\n  &_content {\n\n    display: flex;\n    flex-direction: column;\n    row-gap: 16px;\n\n    h1 {\n      font-family: Ubuntu;\n      font-weight: 500;\n      font-size: 32px;\n      line-height: 36px;\n      letter-spacing: 0%;\n      text-transform: uppercase;\n      color: #4C4C4C;\n    }\n\n    .text {\n      display: flex;\n      flex-direction: column;\n      row-gap: 30px;\n\n      p {\n        font-family: Ubuntu;\n        font-weight: 400;\n        font-size: 18px;\n        line-height: 22px;\n        letter-spacing: 0%;\n        color: #4C4C4C;\n      }\n    }\n  }\n}\n\n.footer_about {\n  border-top: 4px solid #98A6FF;\n  background-color: #8A9AFF;\n}\n\n@media screen and (max-width: 1024px) {\n  .aboutBlock {\n    width: 80%;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .aboutBlock {\n    width: 90%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutBlock": `About_aboutBlock__apbXL`,
	"aminaPic": `About_aminaPic__dlrTY`,
	"aboutBlock_content": `About_aboutBlock_content__106Ur`,
	"text": `About_text__HkgV7`,
	"footer_about": `About_footer_about__T-GEp`
};
export default ___CSS_LOADER_EXPORT___;
