// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Multfilm_footer__9LRx1 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  row-gap: 32px;
  flex-direction: column;
  text-align: center;
  color: white;
}
.Multfilm_footer__9LRx1 p {
  opacity: 0.6;
  margin: 0 auto;
  width: 45%;
}

.Multfilm_footer_multfilm__M8A7X {
  background-color: #8A60EB;
  border-top: 4px solid #9873ED;
}

@media screen and (max-width: 1024px) {
  .Multfilm_footer__9LRx1 p {
    width: 70%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Multfilm/Multfilm.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,cAAA;EACA,UAAA;AACJ;;AAIA;EACE,yBAAA;EACA,6BAAA;AADF;;AAIA;EACE;IACE,UAAA;EADF;AACF","sourcesContent":[".footer {\n  width: 100%;\n  height: 300px;\n  display: flex;\n  justify-content: center;\n  row-gap: 32px;\n  flex-direction: column;\n  text-align: center;\n  color: white;\n\n  p {\n    opacity: 0.6;\n    margin: 0 auto;\n    width: 45%;\n  }\n\n}\n\n.footer_multfilm {\n  background-color: #8A60EB;\n  border-top: 4px solid #9873ED;\n}\n\n@media screen and (max-width: 1024px) {\n  .footer p {\n    width: 70%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Multfilm_footer__9LRx1`,
	"footer_multfilm": `Multfilm_footer_multfilm__M8A7X`
};
export default ___CSS_LOADER_EXPORT___;
