// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_active__5EGpd {
  text-shadow: none !important;
  color: #FFFFFF;
}

.Header_active_multfilm__KJ99\\+ {
  background-color: #8A60EB;
}

.Header_active_songs__bj4zv {
  background-color: #D07DFB;
}

.Header_active_about__hiOJ9 {
  background-color: #00BDF6;
}

.Header_mainBackground__Hs\\+v- {
  background-color: #FE89FF;
}

.Header_multfilmBackground__uu8B- {
  background: linear-gradient(279.56deg, #CDBEFF -1.76%, #B8A3FF 100%);
}

.Header_songsBackground__SoblQ {
  background: linear-gradient(279.56deg, #CDBEFF -1.76%, #B8A3FF 100%);
}

.Header_reviewsBackground__uHFKU {
  background: linear-gradient(279.56deg, #CDBEFF -1.76%, #B8A3FF 100%);
}

.Header_aboutBackground__VL63m {
  background: linear-gradient(297.92deg, #739BFF 33.58%, #9B99FF 100%);
}

.Header_galochka__nygVQ {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,oEAAA;AACF;;AAEA;EACE,oEAAA;AACF;;AAEA;EACE,oEAAA;AACF;;AAEA;EACE,oEAAA;AACF;;AAEA;EACC,eAAA;AACD","sourcesContent":[".active {\n  text-shadow: none !important;\n  color: #FFFFFF;\n}\n\n.active_multfilm {\n  background-color: #8A60EB;\n}\n\n.active_songs {\n  background-color: #D07DFB;\n}\n\n.active_about {\n  background-color: #00BDF6;\n}\n\n.mainBackground {\n  background-color: #FE89FF;\n}\n\n.multfilmBackground {\n  background: linear-gradient(279.56deg, #CDBEFF -1.76%, #B8A3FF 100%);\n}\n\n.songsBackground {\n  background: linear-gradient(279.56deg, #CDBEFF -1.76%, #B8A3FF 100%);\n}\n\n.reviewsBackground {\n  background: linear-gradient(279.56deg, #CDBEFF -1.76%, #B8A3FF 100%);\n}\n\n.aboutBackground {\n  background: linear-gradient(297.92deg, #739BFF 33.58%, #9B99FF 100%);\n}\n\n.galochka {\n cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `Header_active__5EGpd`,
	"active_multfilm": `Header_active_multfilm__KJ99+`,
	"active_songs": `Header_active_songs__bj4zv`,
	"active_about": `Header_active_about__hiOJ9`,
	"mainBackground": `Header_mainBackground__Hs+v-`,
	"multfilmBackground": `Header_multfilmBackground__uu8B-`,
	"songsBackground": `Header_songsBackground__SoblQ`,
	"reviewsBackground": `Header_reviewsBackground__uHFKU`,
	"aboutBackground": `Header_aboutBackground__VL63m`,
	"galochka": `Header_galochka__nygVQ`
};
export default ___CSS_LOADER_EXPORT___;
